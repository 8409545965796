<mat-form-field
  class="input"
  appearance="outline"
  color="primary"
  (click)="touched = true"
>
  <mat-label class="mb-xs" *ngIf="label" [ngClass]="{ asterix: required }">{{
    label
  }}</mat-label>
  <mat-select
    [(ngModel)]="value"
    (ngModelChange)="ngModelChange.emit($event); onChange(value)"
    (change)="onChange(value)"
    (click)="handleClick($event); onTouched()"
    [errorStateMatcher]="matcher"
    [placeholder]="placeholder"
    [disabled]="disabled"
  >
    <mat-option value="" *ngIf="allowEmptyValue">{{
      emptyOptionName
    }}</mat-option>
    <mat-option *ngFor="let option of options" [value]="option">
      <ng-content
        *ngTemplateOutlet="templateRef; context: { option }"
      ></ng-content>
      <span *ngIf="!templateRef">{{ _optionLabel(option) }}</span>
    </mat-option>
  </mat-select>
</mat-form-field>
