import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private _currentAuth = new BehaviorSubject(false);
  currentAuth = this._currentAuth.asObservable();

  constructor(@Inject(DOCUMENT) private document: Document) {}

  resizeUp() {
    const sidebar = this.document.getElementById('leftsidebar') as HTMLElement;
    const wrapper = this.document.getElementById(
      'container-wrapper'
    ) as HTMLElement;

    sidebar.style.width = '260px';

    if (wrapper) {
      wrapper.style.marginLeft = '280px';
    }
  }

  resizeDown() {
    const sidebar = this.document.getElementById('leftsidebar') as HTMLElement;
    const wrapper = this.document.getElementById(
      'container-wrapper'
    ) as HTMLElement;

    sidebar.style.width = '65px';
    if (wrapper) {
      wrapper.style.marginLeft = '80px';
    }
  }

  changeAuth(auth: boolean) {
    this._currentAuth.next(auth);
  }

  buildQueryUrl(query: any) {
    let url = '';
    if (query) {
      for (const [key, value] of Object.entries(query)) {
        url = `${url}&${key}=${value}`;
      }
    }
    return url;
  }
}
