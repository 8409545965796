import { Injectable } from '@angular/core';
import { BaseApi } from './base/base-api.service';
import { Pagination } from '@/interfaces/Pagination';
import { User } from '@/interfaces/User';
import { CreateUserDto } from '@/models/dto/crate-user.dto';
import { PasswordResetDto } from '@/interfaces/dto/PasswordResetDto';
@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseApi {
  findAll(params: any) {
    return this.get<Pagination<User>>('/users', { params });
  }

  create(data: CreateUserDto) {
    return this.post('/users', data);
  }

  findById(id: number) {
    return this.get<User>(`/users/${id}`);
  }

  update(id: number, data: CreateUserDto) {
    return this.put(`/users/${id}`, data);
  }

  passwordReset(data: PasswordResetDto) {
    return this.put(`/users/password/reset`, data);
  }
}
