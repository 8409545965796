import { ChangeDetectionStrategy } from '@angular/compiler';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loadingSubject = new BehaviorSubject<boolean>(false);

  loading$: Observable<boolean> = this.loadingSubject.asObservable();

  hide(): void {
    this.loadingSubject.next(false);

  }

  show(): void {
    this.loadingSubject.next(true);
  }
}
