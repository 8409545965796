import { Injectable } from '@angular/core';
import { UserLoginModel } from '../models/user-login.model';
import { environment } from '../../environments/environment';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { UsuarioModel } from '@/models/usuario.model';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  url = environment.baseURL;

  constructor(private httpClient: HttpClient, private router: Router) {}

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  login(userLoginData: UserLoginModel): Observable<any> {
    return this.httpClient
      .post<UserLoginModel>(`${this.url}/auth/login`, userLoginData)
      .pipe(catchError(this.handleError as any));
  }

  logout() {
    return this.httpClient
      .post<UserLoginModel>(`${this.url}/logout`, {})
      .pipe(catchError(this.handleError as any));
  }

  loggedIn(): Boolean {
    return !!localStorage.getItem('token');
  }

  findOnUsuario(id: string) {
    return this.httpClient
      .get<UsuarioModel>(`${this.url}/user/${id}`)
      .pipe(catchError(this.handleError as any));
  }

  findLoginUsuario(login: string): Observable<any> {
    return this.httpClient
      .get<any>(`${this.url}/buscando/${login}`)
      .pipe(catchError(this.handleError as any));
  }

  userCurrent() {
    const access_token = this.getToken();
    if (access_token) {
      const decoded: UsuarioModel = jwt_decode(access_token);
      return decoded;
    }
    return null;
  }

  // Manipulação de erros
  handleError(error: any, isSubscribe: boolean) {
    let errorMessage: any = {};

    if (error?.error instanceof ErrorEvent) {
      // Erro ocorreu no lado do client
      errorMessage = {
        message: error.error ? error.error.message : 'Erro client desconhecido',
      };
    } else {
      // Erro ocorreu no lado do servidor
      errorMessage = {
        status: error?.statusCode,
        message:
          error?.error?.message || error?.message || 'Erro server desconhecido',
      };
    }

    if (!errorMessage?.message) {
      errorMessage.message = error.message;
    }

    return isSubscribe ? throwError(() => errorMessage) : errorMessage;
  }

  callRefreshToken(refreshToken: any): Observable<any> {
    return this.httpClient
      .post(`${this.url}/refresh`, refreshToken)
      .pipe(catchError(async (err) => this.handleError(err, true)));
  }

  getRefreshToken() {
    return localStorage.getItem('refreshToken');
  }

  getToken() {
    return localStorage.getItem('token');
  }

  setData(result: any) {
    localStorage.setItem('token', result.access_token);
    localStorage.setItem('refreshToken', result.refresh_token);
    localStorage.setItem('resetPassword', result.resetPassword ? '1' : '0');
  }

  clearData() {
    localStorage.clear();
  }

  resetUser() {
    this.clearData();
    this.router.navigate(['/login']);
  }
}
