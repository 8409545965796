import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MenuAdministracaoComponent } from './menu-administracao.component';
import { TrocarSenhaComponent } from './trocar-senha/trocar-senha.component';
import { PerfisComponent } from './perfis/perfis.component';
import { AclGuard } from 'src/app/guards/acl.guard';

const routes: Routes = [
  {
    path: 'menu',
    component: MenuAdministracaoComponent,
  },
  {
    path: 'perfis',
    component: PerfisComponent,
    canActivate: [AclGuard],
    data: {
      permissions: [],
    },
  },
  {
    path: 'trocar-senha',
    component: TrocarSenhaComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdministracaoRoutingModule {}
