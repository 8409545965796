import { LoadingService } from '@/services/loading.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  error = '';
  userCurrent: any;

  @ViewChild('stepper')
  private myStepper: MatStepper;
  loginFormGroup: FormGroup;
  passFormGroup: FormGroup;
  twoFactorFormGroup: FormGroup;

  constructor(
    private authService: AuthService,
    private _formBuilder: UntypedFormBuilder,
    private router: Router,
    private sharedService: SharedService,
    private loadingService: LoadingService
  ) {
    this.loginFormGroup = this._formBuilder.group({
      username: ['', Validators.required],
    });

    this.passFormGroup = this._formBuilder.group({
      password: ['', Validators.required],
    });

    this.twoFactorFormGroup = this._formBuilder.group({
      token: [''],
    });
  }

  ngOnInit(): void {
    this.sharedService.changeAuth(false);

    if (this.authService.loggedIn()) {
      this.router.navigate(['/home']);
    }
  }

  valida2Fa() {
    if (this.userCurrent.ativo_2fa && this.userCurrent.secret) {
      this.myStepper.next();
      return;
    }

    this.login();
  }

  get hasError() {
    return this.loginFormGroup.invalid || this.passFormGroup.invalid;
  }

  login(): void {
    this.error = '';

    if (this.hasError) {
      return;
    }

    this.loadingService.show();
    this.authService
      .login({
        login: this.loginFormGroup.get('username')?.value,
        senha: this.passFormGroup.get('password')?.value,
        otp: this.twoFactorFormGroup.get('token')?.value,
      })
      .subscribe(
        (res) => {
          this.sharedService.changeAuth(true);
          this.authService.setData(res);

          if (res.resetPassword) {
            location.href = '/administracao/trocar-senha';
            return;
          }

          location.href = '/home';
          this.loadingService.hide();
        },
        (err: HttpErrorResponse) => {
          this.error = err.message;
          this.loadingService.hide();
        }
      );
  }

  findLogin() {
    if (this.loginFormGroup.invalid) return;

    this.loadingService.show();
    const { username } = this.loginFormGroup.value;
    this.authService.findLoginUsuario(username).subscribe(
      (res) => {
        if (res.usuario) {
          this.userCurrent = res.usuario;
          this.error = '';
          this.loadingService.hide();
          this.myStepper.next();
        } else {
          this.error = 'Corrija as informações';
        }
      },
      (err: HttpErrorResponse) => {
        this.error = err.message;
        this.loadingService.hide();
      }
    );
  }

  voltarParaEmail() {
    this.error = '';
    this.myStepper.selectedIndex = 1;
  }

  nextStep() {
    this.myStepper.next();
  }
}
