import { environment } from '@/environments/environment';
import { OptionsQuery } from '@/models/api.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseApi {
  readonly url: string = '';

  constructor(protected http: HttpClient) {
    this.url = environment.baseURL;
  }

  protected get<T>(path: string, options?: OptionsQuery | {}): Promise<T> {
    options = options || {};
    return lastValueFrom(this.http.get<T>(this.url.concat(path), options));
  }

  protected post<T>(path: string, data: any): Promise<T> {
    return lastValueFrom(this.http.post<T>(this.url.concat(path), data));
  }

  protected put<T>(path: string, data: any): Promise<T> {
    return lastValueFrom(this.http.put<T>(this.url.concat(path), data));
  }

  protected patch<T>(path: string, data: any): Promise<T> {
    return lastValueFrom(this.http.patch<T>(this.url.concat(path), data));
  }

  protected delete<T>(path: string, data: any = {}): Promise<T> {
    return lastValueFrom(
      this.http.request<T>('DELETE', this.url.concat(path), {
        body: data,
      })
    );
  }
}
