import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuAdministracaoComponent } from './menu-administracao.component';
import { PerfisComponent } from './perfis/perfis.component';
import { TrocarSenhaComponent } from './trocar-senha/trocar-senha.component';
import { AdministracaoRoutingModule } from './administracao-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatRadioModule } from '@angular/material/radio';
import { NgxEditorModule } from 'ngx-editor';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  declarations: [
    MenuAdministracaoComponent,
    PerfisComponent,
    TrocarSenhaComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    AdministracaoRoutingModule,
    MatCardModule,
    MatGridListModule,
    MatToolbarModule,
    MatRadioModule,
    MatExpansionModule,
    NgxEditorModule,
    MatAutocompleteModule,
  ],
  providers: [],
})
export class AdministracaoModule {}
