import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RefreshTokenInterceptor } from './services/interceptors/refresh-token.interceptor';
import { DialogComponent } from './components/dialog/dialog.component';
import { CurrencyPipe, DatePipe, registerLocaleData } from '@angular/common';
import { AccountModule } from './modules/account/account.module';
import { LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import {
  CurrencyMaskConfig,
  CURRENCY_MASK_CONFIG,
  CurrencyMaskModule,
} from 'ng2-currency-mask';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from './shared/shared.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import ptBr from '@angular/common/locales/pt';
import { ProgressBarLoadingComponent } from './components/progress-bar-loading/progress-bar-loading.component';
import { LoadingInterceptor } from './services/interceptors/loading.interceptor';
import { ErrorHandler } from './http/error.interceptor';
import { AdministracaoModule } from './modules/private/menu-administracao/administracao.module';

registerLocaleData(ptBr);

const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'right',
  allowNegative: true,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.',
};

@NgModule({
  declarations: [AppComponent, DialogComponent, ProgressBarLoadingComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AccountModule,
    SharedModule,
    NoopAnimationsModule,
    HttpClientModule,
    CurrencyMaskModule,
    MatFormFieldModule,
    MatInputModule,
    RouterModule,
    MatIconModule,
    BrowserAnimationsModule,
    AdministracaoModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandler,
      multi: true,
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'pt-BR',
    },
    { provide: LOCALE_ID, useValue: 'pt' },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL',
    },
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    DatePipe,
    CurrencyPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
