import { Injectable } from '@angular/core';
import { BaseApi } from './base/base-api.service';
import {
  UsuarioPerfilPermissao,
  UsuarioPerfilPermissaoCount,
} from '../@types/usuario-perfil';

@Injectable({
  providedIn: 'root',
})
export class UsuarioPerfilService extends BaseApi {
  findAll(): Promise<UsuarioPerfilPermissaoCount[]> {
    return this.get(`/usuarios-perfil`);
  }

  findOne(id: number): Promise<UsuarioPerfilPermissao> {
    return this.get(`/usuarios-perfil/${id}`);
  }

  updatePermissions(
    id: number,
    data: { permissao_ids: number[] }
  ): Promise<UsuarioPerfilPermissao> {
    return this.put(`/usuarios-perfil/${id}/permissoes`, data);
  }
}
